export default {
  en: {
    partner: 'Partner',
    withdrawType: 'WITHDRAW',
    depositType: 'DEPOSIT',
    exchangeType: 'EXCHANGE',
    directBonusType: 'DIRECT BONUS',
    weeklyBonusType: 'WEEKLY BONUS',
    globalPoolGoldType: 'GLOBAL POOL GOLD',
    globalPoolDiamondType: 'GLOBAL POOL DIAMOND',
    depositFixBonusType: 'DEPOSIT FIX BONUS',
    depositUsdBonusType: 'DEPOSIT USD BONUS',
    withdrawFixBonusType: 'WITHDRAW FIX BONUS',
    withdrawUsdBonusType: 'WITHDRAW USD BONUS',
    depositFixGoldType: 'DEPOSIT FIX GOLD',
    autoExchangeType: 'AUTO EXCHANGE',
    withdrawFixGoldType: 'WITHDRAW FIX GOLD',
    exchangeFixGoldType: 'EXCHANGE FIX GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'EXCHANGE FIX ONE',
    viewTransaction: 'View',
    previousArrow: 'Previous',
    nextArrow: 'Next',
    noData: 'No data available in table',
    withdrawVideo: 'https://www.youtube.com/embed/GFlM1c-BIwY',
    declined: 'Declined',
    declinedByUser: 'Declined by user',
  },
  ru: {
    partner: 'Партнёрский',
    withdrawType: 'ВЫВОД',
    depositType: 'ДЕПОЗИТ',
    exchangeType: 'ОБМЕН',
    directBonusType: 'ЛИЧНЫЙ БОНУС',
    weeklyBonusType: 'НЕДЕЛЬНЫЙ БОНУС',
    globalPoolGoldType: 'GLOBAL POOL GOLD',
    globalPoolDiamondType: 'GLOBAL POOL DIAMOND',
    depositFixBonusType: 'ДЕПОЗИТ FIX БОНУС',
    depositUsdBonusType: 'ДЕПОЗИТ USD БОНУС',
    withdrawFixBonusType: 'ВЫВОД FIX БОНУС',
    withdrawUsdBonusType: 'ВЫВОД USD БОНУС',
    depositFixGoldType: 'ДЕПОЗИТ FIX GOLD',
    autoExchangeType: 'АВТО ОБМЕН',
    withdrawFixGoldType: 'ВЫВОД FIX GOLD',
    exchangeFixGoldType: 'ОБМЕН FIX GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'ОБМЕН FIX ONE',
    viewTransaction: 'Смотреть',
    previousArrow: 'Назад',
    nextArrow: 'Вперед',
    noData: 'Данные в таблице отсутствуют',
    withdrawVideo: 'https://www.youtube.com/embed/Yi71rKJH6Ac',
    declined: 'Отклонен',
    declinedByUser: 'Отклонено пользователем',
  },
  es: {
    partner: 'Partner',
    withdrawType: 'RETIRO',
    depositType: 'DEPOSITAR',
    exchangeType: 'INTERCAMBIO',
    directBonusType: 'BONIFICACIÓN DIRECTA',
    weeklyBonusType: 'BONIFICACIÓN SEMANAL',
    globalPoolGoldType: 'GLOBAL POOL ORO',
    globalPoolDiamondType: 'GLOBAL POOL DIAMANTE',
    depositFixBonusType: 'CARGAR BONIFICACIÓN FIX',
    depositUsdBonusType: 'CARGAR BONIFICACIÓN USD',
    withdrawFixBonusType: 'RETIRAR BONIFICACIÓN FIX',
    withdrawUsdBonusType: 'RETIRAR BONIFICACIÓN USD',
    depositFixGoldType: 'DEPOSIT FIX GOLD',
    autoExchangeType: 'AUTO EXCHANGE',
    withdrawFixGoldType: 'WITHDRAW FIX GOLD',
    exchangeFixGoldType: 'INTERCAMBIO FIX GOLD',
    depositCryptohouseType: 'DEPOSIT CRYPTOHOUSE',
    exchangeFixOneType: 'EXCHANGE FIX ONE',
    viewTransaction: 'Ver',
    previousArrow: 'Anterior',
    nextArrow: 'Siguiente',
    noData: 'No hay datos disponibles en la tabla',
    withdrawVideo: 'https://www.youtube.com/embed/U29Uai6oyxY',
    declined: 'Declinado',
    declinedByUser: 'Rechazado por el usuario',
  }
}
